import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
// import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import moment from 'moment/moment';
// snackbar notification
import Snackbar from '@mui/material/Snackbar';
// MUI Dialog components
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
// import WarningIcon from '@mui/icons-material/Warning';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
// User details components
// import Cookies from 'js-cookie';
import { LanguageContext } from '../../context/languageContext';

const RepairOrderSearch = () => {
  const [repairOrder, setRepairOrder] = useState('');
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  // const [showMap, setShowMap] = useState(false); // State to control map visibility
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  //   const [dialogOpen, setDialogOpen] = useState(false);
  //   const [userDetails, setUserDetails] = useState(null);
  const { translations, language, changeLanguage } =
    useContext(LanguageContext);

  // User Details
  //   useEffect(() => {
  //     const fetchUserDetails = async () => {
  //       const usersEndpoint = process.env.REACT_APP_ENDPOINT_USERS;
  //       const token = Cookies.get('token');
  //       if (!token) {
  //         console.error('Access token is not available.');
  //         return;
  //       }

  //       try {
  //         const response = await axios.get(`${usersEndpoint}/user-details`, {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         });
  //         if (response.data) {
  //           setUserDetails(response.data);
  //         }
  //       } catch (error) {
  //         // console.error('Failed to fetch user details:', error);
  //         setUserDetails({});
  //       }
  //     };

  //     fetchUserDetails();
  //     // console.log(fetchUserDetails());
  //   }, []);

  // Snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Searching for Device Information
  const handleSearch = async repairOrder => {
    if (!repairOrder) {
      alert('Please enter a Repair Order');
      return;
    }

    try {
      //   console.log('Repair Order being sent:', repairOrder); // Debug repairOrder value

      const res = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_REPORTING}/depot/repairOrder/${repairOrder}`
      );

      setResult(res.data[0]);
      setError(null);
      setSnackbarMessage('Repair Order Search Successful');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(`Error: ${error.message}`);
      setResult(null);
    }
  };

  // Clearing Search Results
  const handleClear = () => {
    setRepairOrder('');
    setResult(null);
    setError(null);
    setSnackbarMessage('Repair Order Search Cleared');
    setSnackbarOpen(true);
  };

  const toTitleCase = str => {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const fields = [
    // { label: 'ID', key: 'id' },
    { label: 'Repair Order', key: 'repairOrder' },
    { label: 'Date', key: 'date', format: 'date' },
    { label: 'Open Case', key: 'openCase' },
    { label: 'Case Number', key: 'caseNumber' },
    { label: 'OEM', key: 'oem' },
    { label: 'RRF Repair', key: 'rrfRepair' },
    { label: 'Start Date', key: 'startDate', format: 'date' },
    { label: 'Completion Date', key: 'completionDate', format: 'date' },
    { label: 'Jurisdiction', key: 'jurisdiction' },
    { label: 'RMA Number', key: 'rmaNumber' },
    { label: 'Asset Tag', key: 'assetTag' },
    { label: 'Serial Number', key: 'serialNumber' },
    { label: 'Failure Mode', key: 'failureMode' },
    { label: 'Solution One', key: 'solutionOne' },
    { label: 'Solution Two', key: 'solutionTwo' },
    { label: 'Parts Used', key: 'partsUsed' },
    { label: 'Comm Device', key: 'commDevice' },
    { label: 'Terminal', key: 'terminal' },
    { label: 'Printer', key: 'printer' },
    { label: 'Peripherals', key: 'peripherals' },
    { label: 'Display Monitors', key: 'displayMonitors' },
    { label: 'Status', key: 'status' },
    { label: 'Total Work Time', key: 'totalWorkTime' },
    { label: 'Is Second Time Return', key: 'isSecondTimeReturn' },
    { label: 'Return Date', key: 'returnDate', format: 'date' },
    { label: 'Product', key: 'product' },
    { label: 'Reader', key: 'reader' },
    { label: 'Power Supply', key: 'powerSupply' },
    { label: 'PCB', key: 'pcb' },
    { label: 'Bill Acceptor', key: 'billAcceptor' },
    { label: 'Burster', key: 'burster' },
    { label: 'Comments', key: 'comments' },
    { label: 'Last Modified', key: 'lastModified' },
    { label: 'Display Name', key: 'displayName' },
  ];

  return (
    <>
      <div className='container' style={{ display: 'flex' }}>
        <div style={{ flex: 1, padding: '10px' }}>
          {/* <h1>Search Device</h1> */}
          <Stack
            direction='row'
            spacing={2}
            sx={{
              marginBottom: 2,
              margin: '10px',
            }}
          >
            <Button
              onClick={() => handleSearch(repairOrder)}
              variant='outlined'
              color='primary'
            >
              {translations.search || 'Search'}
            </Button>
            <Button onClick={handleClear} variant='outlined' color='warning'>
              {translations.clear || 'Clear'}
            </Button>
          </Stack>
          {/* <input
						type="text"
						value={serial}
						onChange={(e) => setRepairOrder(e.target.value)}
						placeholder={translations.searchSerialNumber || 'Search'}
					/> */}
          <TextField
            type='text'
            value={repairOrder}
            onChange={e => setRepairOrder(e.target.value)}
            placeholder={'Search'}
            variant='outlined'
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => handleSearch(repairOrder)}
                    aria-label='search'
                  >
                    <SearchIcon
                      sx={{
                        color: '#053469',
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label={'Enter Repair Order Number'}
          />
          <br />
          <br />
          <div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {result && (
              <Card
                variant='outlined'
                style={{ display: 'flex', width: '100%' }}
              >
                <CardContent style={{ flex: 1, display: 'flex' }}>
                  <div style={{ flex: 1 }}>
                    {fields.map(({ label, key, format }) => (
                      <Typography
                        sx={{ mb: 1.5 }}
                        color='text.secondary'
                        key={key}
                      >
                        {label}:{' '}
                        <span style={{ color: '#154db4' }}>
                          {format === 'date'
                            ? result[key]
                              ? moment(result[key]).format('MMMM Do, YYYY')
                              : 'N/A'
                            : result[key]
                            ? key === 'repairOrder'
                              ? result[key] // Leave repairOrder unchanged
                              : toTitleCase(result[key].toString()) // Apply title case for all other fields
                            : 'N/A'}
                        </span>
                      </Typography>
                    ))}
                  </div>
                </CardContent>
              </Card>
            )}
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        ContentProps={{
          style: {
            backgroundColor: '#4CAF50',
          },
        }}
      />
    </>
  );
};

export default RepairOrderSearch;
