import DepotTabs from '../components/depot/repairOrderSearch';
import { LanguageContext } from '../context/languageContext';

export const utilities = translations => [
  {
    tab: 'Repair Order',
    items: [
      {
        title: 'Repair Order',
        description: 'Devices',
        component: <DepotTabs />,
      },
    ],
  },
];
