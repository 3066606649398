import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import DropDown from '../../components/dropDown';
import DataGridComponent from '../../components/datagrid';
import {
  lotteryRepairWorklogReport,
  gamingRepairWorklogReport,
  incorrectCaseReport,
  fstOvertimeReport,
  benchRepairReport,
  commAuditLog,
  czSurvey,
  californiaPTLReport,
  forwardToSiteReport,
  gnieUpdateRequestReport,
  retailerInfoChangeReport,
  riCreditLogReport,
  washingtonRetailerTrainingRequestReport,
  consumableSupplyReport,
  nyKeyRequestReport,
  dispatchStatusChangeReport,
  nrcDowntimeReport,
  leewardIslandsCreditLogReport,
  miIncidentLogReport,
  nyAmLogReport,
} from '../../utils/reportConfigs';
import NavigationBar from '../../components/navigationBar';
import { reportMenuData } from '../../utils/menuData';
import { LanguageContext } from '../../context/languageContext';

const Reports = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { translations, language, changeLanguage } =
    useContext(LanguageContext);

  const [selectedCountry, setSelectedCountry] = useState(
    queryParams.get('country') || 'United States'
  );
  const [selectedTeam, setSelectedTeam] = useState(
    queryParams.get('team') || ''
  );
  const [selectedReport, setSelectedReport] = useState(
    queryParams.get('report') || ''
  );
  const [showReport, setShowReport] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const teams =
    reportMenuData(translations).find(data => data.country === selectedCountry)
      ?.teams || [];
  const reports = teams.find(team => team.name === selectedTeam)?.reports || [];

  useEffect(() => {
    const params = new URLSearchParams();
    if (selectedCountry) params.set('country', selectedCountry);
    if (selectedTeam) params.set('team', selectedTeam);
    if (selectedReport) params.set('report', selectedReport);
    navigate({ search: params.toString() });
  }, [selectedCountry, selectedTeam, selectedReport, navigate]);

  const handleCountryChange = event => {
    setSelectedCountry(event.target.value);
    setSelectedTeam('');
    setSelectedReport('');
    setShowReport(false);
  };

  const handleTeamChange = event => {
    setSelectedTeam(event.target.value);
    setSelectedReport('');
    setShowReport(false);
  };

  const handleReportChange = event => {
    setSelectedReport(event.target.value);
  };

  const handleShowReportClick = () => {
    if (selectedReport) setShowReport(true);
  };

  const reportConfigs = {
    FSTOvertimeReport: fstOvertimeReport(translations),
    GamingRepairWorklogReport: gamingRepairWorklogReport(translations),
    LotteryRepairWorklogReport: lotteryRepairWorklogReport(translations),
    IncorrectCaseReport: incorrectCaseReport(translations),
    BenchRepairReport: benchRepairReport(translations),
    CommDeviceAudit: commAuditLog,
    CZGenesysCloudSurvey: czSurvey(translations),
    CaliforniaPTLReport: californiaPTLReport,
    ForwardToSiteReport: forwardToSiteReport,
    GNIEUpdateRequestReport: gnieUpdateRequestReport,
    RetailerInfoChangeReport: retailerInfoChangeReport,
    RICreditLogReport: riCreditLogReport,
    WARetailerTrainingRequestReport: washingtonRetailerTrainingRequestReport,
    NYKeyRequestReport: nyKeyRequestReport,
    DispatchStatusChangeRequestReport: dispatchStatusChangeReport,
    ConsumableSupplyReport: consumableSupplyReport,
    NRCDowntimeReport: nrcDowntimeReport,
    LeewardIslandCreditLogReport: leewardIslandsCreditLogReport,
    MichiganIncidentLogReport: miIncidentLogReport,
    NYAMLogReport: nyAmLogReport,
  };

  const renderReport = () => {
    if (!showReport) return null;

    const reportConfig = reports.find(
      form => form.componentKey === selectedReport
    );

    if (
      reportConfig?.componentKey &&
      reportConfigs[reportConfig.componentKey]
    ) {
      return (
        <DataGridComponent
          apiEndpoint={reportConfigs[reportConfig.componentKey].apiEndpoint}
          columns={reportConfigs[reportConfig.componentKey].columns}
          translations={translations}
        />
      );
    } else {
      return (
        <Typography>
          {translations.selectReport || 'Select a Report to begin'}
        </Typography>
      );
    }
  };

  return (
    <>
      <NavigationBar />
      <Box sx={{ flexGrow: 1, margin: '20px' }}>
        <Grid container spacing={2}>
          {isMobile && (
            <Grid item xs={12}>
              <Button
                variant='outlined'
                onClick={() => setIsMenuVisible(!isMenuVisible)}
                sx={{
                  mb: 2,
                  flex: 1,
                  backgroundColor: isMenuVisible ? '#0a417a' : '#0c51a1',
                  '&:hover': {
                    backgroundColor: isMenuVisible ? '#08305a' : '#0b4690',
                  },
                }}
              >
                {isMenuVisible
                  ? translations.hideFilters || 'Hide Filters'
                  : translations.selectFilters || 'Select Filters'}
              </Button>
            </Grid>
          )}
          {(!isMobile || isMenuVisible) && (
            <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: 2,
                  padding: '20px',
                  height: '100%',
                  width: { xs: 'auto', md: '90vw' },
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <DropDown
                    items={reportMenuData(translations).map(data => ({
                      value: data.country,
                      label: data.country,
                    }))}
                    selectedValue={selectedCountry}
                    onChange={handleCountryChange}
                    placeholder={translations.selectCountry || 'Select Country'}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <DropDown
                    items={teams.map(team => ({
                      value: team.name,
                      label: team.name,
                    }))}
                    selectedValue={selectedTeam}
                    onChange={handleTeamChange}
                    placeholder={translations.selectTeam || 'Select Team'}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <DropDown
                    items={reports.map(form => ({
                      value: form.componentKey,
                      label: form.name,
                    }))}
                    selectedValue={selectedReport}
                    onChange={handleReportChange}
                    placeholder={translations.selectReport || 'Select Report'}
                  />
                  <Button
                    variant='outlined'
                    onClick={handleShowReportClick}
                    sx={{ mt: 2 }}
                    disabled={!selectedReport}
                  >
                    {translations.loadReport || 'Load Report'}
                  </Button>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          order={{ xs: 2, md: 1 }}
          sx={{ mt: { xs: '10%', md: 'auto' } }}
        >
          {renderReport()}
        </Grid>
      </Box>
    </>
  );
};

export default Reports;
